#page-about{

    .about, .responsability{
        @extend .container;

        @include media-breakpoint-up(lg) {
            max-width: 100%;
            @each $breakpoint,
            $max-width in $container-max-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    margin: {
                        left: calc((100vw - #{$max-width}) / 2 + 15px);
                    }
                }
            }
        }
    }

    .develop{
        .bg-contain{
            height: 100%;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            @include media-breakpoint-down(md) {
                height: 200px;
            }
        }
    }

    .responsability{

        @extend .section;
        
        @include media-breakpoint-down(lg) {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
                
        @include media-breakpoint-up(xl) {
            padding-bottom: 0 !important;
        }

        .text-container{
            padding: 250px 0;
            
            @include media-breakpoint-down(lg) {
                padding: 0 0 50px 0;
            }

        }
    }

}