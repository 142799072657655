.contact-partial{
    position: relative;

    &:before{
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        
        background: rgba($blue, .7);
    }
}