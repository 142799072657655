#page-404{
    height: 100vh;

    .page-inner{
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}