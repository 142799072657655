/*
|--------------------
|     PAGE HOME
|--------------------
*/

.section-home {
    .swiper-home {
        .swiper-slide {
            .slider-item {
                .item-img {
                    width: 100%;
                    height: 60vh;
                    background-size: cover;
                    background-position: center;
                        &:before {
                            border-top-width: 630px;
                            border-bottom-width: 0;
                            border-right-width: 200px;
                            border-left-width: 0;
                            border-top-color: rgba(0, 55, 100, 0.8);
                            border-right-color: transparent;
                            border-bottom-color: #013763;
                            border-left-color: transparent;
                            border-style: solid;
                            content: "";
                            left: 0;
                            opacity: 0.8;
                            position: absolute;
                            top: 0;
                            width: 37%;
                            z-index: 0;
                    }
                }
            }
        }
    }
    .swiper-pagination {
        z-index: 0;
        .swiper-pagination-bullet {
            height: 15px;
            width: 15px;
            background-color: #666666;
            margin-left: 10px;
            opacity: 1;
            outline: none;
            &.swiper-pagination-bullet-active {
                background-color: $blue;
            }
        }
    }
}