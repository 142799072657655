/*
|---------------------------------------------------------------
| Helpers
|---------------------------------------------------------------
| Define helper classes
|
|
*/

/*
|
| Text Transform
|----------------------------
|
*/
.tt-u{text-transform: uppercase !important}
.tt-l{text-transform: lowercase !important}
.tt-c{text-transform: capitalize !important}

/*
|
| Underline
|------------
|
*/
.td-u{text-decoration: underline !important}
.td-n{text-decoration: none !important}

/*
|
| Font styles
|--------------
|
*/
.fs-i{font-style: italic !important}
.fs-o{font-style: oblique !important}
.fs-n{font-style: normal !important}


/*
|
| Background cover
|-------------------
|
*/
.bg-cover{
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.bg-cover-top{
  @extend .bg-cover;
  background-position: center top !important;
}
.bg-cover-bottom {
  @extend .bg-cover;
  background-position: center bottom !important;
}

/*
|
| Overlays
|-------------
|
*/
.overlay{
  position: absolute;
  z-index:1;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background-color: $default-background-overlay;

  + *{
    position: relative;
    z-index: 2;
  }
}

/*
|
| Overflows
|-------------
|
*/
.o-v{overflow: visible !important;}
.ox-v{overflow-x: visible !important;}
.oy-v{overflow-y: visible !important;}
.o-h{overflow: hidden !important;}
.ox-h{overflow-x: hidden !important;}
.oy-h{overflow-y: hidden !important;}

/*
|
| Positions
|-------------
|
*/
.p-r{position: relative !important;}
.p-a{position: absolute !important;}
.p-f{position: fixed !important;}
.p-s{position: static !important;}

/*
|
| Flex Alignment
|-----------------
|
*/
.ai-start{ align-items: flex-start; }
.ai-end{ align-items: flex-end; }
.ai-center{ align-items: center; }
.ai-baseline{ align-items: baseline; }
.ai-stretch{ align-items: stretch; }

/*
|
| Flex Justify
|---------------
|
*/
.jc-start{ justify-content: flex-start; }
.jc-end{ justify-content: flex-end; }
.jc-center{ justify-content: center; }
.jc-between{ justify-content: space-between; }
.jc-around{ justify-content: space-around; }

/*
|
| White space
|--------------
|
*/
.ws-inherit{ white-space: inherit; }
.ws-normal{ white-space: normal; }
.ws-nowrap{ white-space: nowrap; }
.ws-pre{ white-space: pre; }
.ws-pre-wrap{ white-space: pre-wrap; }
.ws-pre-line{ white-space: pre-line; }

/*
|
| Pointer events
|-----------------
|
*/
.pe-inherit{ pointer-events: inherit; }
.pe-none{ pointer-events: none; }
.pe-auto{ pointer-events: auto; }


/*
|
| Optimize animation
|---------------------
|
*/
.optimize-animation {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000;
  -webkit-perspective: 1000;
  will-change: transform;
}

/*
|
| transform-none
|-----------------
|
*/
.transform-none{ transform: none !important; }

/*
|
| transform-none
|-----------------
|
*/
.transform-none {
    transform: none !important;
}


/*
|
| absolute-full
|-----------------
|
*/
.absolute-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*
|
| Clear floats
|---------------
|
*/
.clear {
    clear: both;
}

/*
|
| img full
|-----------
|
*/
.img-full {
    display: inline-block;
    max-width: inherit;
    width: 100%;
}

.bg-contain{
    background-size: contain !important;
}

.row{
    &.reverse{
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }
    }
}

/*
|
| W-100 H-100
|-----------
|
*/
.w-100{
    width: 100%;
}
.h-100 {
    height: 100%;
}