/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select{
    -webkit-appearance: none;
    border-radius: 0;

    &:focus{
        outline: none;
    }
}

select{
    background-image: url('../img/icon/bottom.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap{
    .gform_fields{
        @extend .row;

        input, textarea{
            width: 100%;
            background-color: transparent;
        }
    }
}


/*
|
| Contact form 
|---------------
*/
.contact-form{

    .validation_error{
        display: none;
    }

    .gfield{
        margin-bottom: 20px;
    }

    label{
        color: $light-grey;
        
        font-weight: 700;
        font-weight: 13px;

        margin-bottom: 5px;

        .gfield_required{
            color: $red;
            margin-left: 10px;
        }
    }

    input{
        border: 1px $very-dark-grey solid;
        border-radius: 4px;

        padding: 5px 10px;
    }

    textarea{
        border: 1px $very-dark-grey solid;
        border-radius: 4px;

        height: 120px;
        padding: 5px 10px;

        color: $very-dark-grey;

        &.placeholder_disabled{
            color: $very-dark-grey;
        }
    }

    .gform_body{
        margin-bottom: 20px;
    }

    .gform_footer {
        
    }

    input[type="submit"]{
        @extend .btn;
        @extend .btn-blue;

    }

    .gform_confirmation_message{
        color: $very-dark-grey;
    }

    .validation_message{
        margin-top: 5px;
        color: #BB0B0B;
        font-size: 14px;
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}


/*
|
| UI check group
|-----------------
*/
.ui-check-group{
    .gfield_label{
        display: none;
    }
    
    label, input{
        cursor: pointer;
    }

    label{
        position: relative;
        padding-left: 30px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 4px;
            width: 15px;
            height: 15px;
            background-color: #efefef;
        }

        &:before {
            display: inline-block;
            //border: 2px solid $blue;
        }

        &:after {
            z-index: 1;
            background-image: url('../img/icon/check.svg');
            background-size: 9px;
            background-repeat: no-repeat;
            background-position: center center;
            opacity: 0;
            transform: translateY(5px);
            transition: opacity 0.2s ease, transform 0.2s ease;
        }
    }

    input{
        position: absolute;
        opacity: 0;

        &:checked{
            & + label{
                &:after {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    // &.cg-white{
    //     label{
    //         &:before{
    //             border: 1px solid $white;
    //         }
    //     }

    //     input{
    //         &:checked{
    //             &+label {
    //                 &:after {
    //                     opacity: 1;
    //                     transform: translateY(0);
    //                     background-color: $white;
    //                 }
    //             }
    //         }
    //     }
    // }
}