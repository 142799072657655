#page-catalogue{
    #section-menu{
        .menu-products-list, .arrow-filter{
            display: none;
        }
    }

    .sectionAttributesMenu {
        h3 {
            font-size: 14px;
            text-transform: none;
        }

        label {
            position: relative;
            padding-left: 20px;
            cursor: pointer;
            display: block;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 14px;
                height: 14px;
                border: 1px solid $light-grey;
                transform: translateY(-50%);
                transition: .3s all;
            }

            &.active{
                color: $blue;

                &::before {
                    border: 4px solid $blue;
                }
            }
        }
    }

}

#section-menu{

    .menu-cat-item,
    .menu-product-item{
        border-bottom: 1px solid $light-grey;
        padding: 15px 15px;
        
        transition: .3s all;

        &:hover{
            border-bottom: 1px solid $blue;
            color: $blue;
        }
    }

    .menu-frequency-item{
        padding: 15px 15px;
        
        color: $blue;
        background: $shop-grey;

        font-weight: 700;
        border: 0;
    }

    .menu-cat-item{
        text-transform: uppercase;
        cursor: pointer;

        &.active{
            font-weight: 700;
        }

    }

    .menu-product-item {
        padding: 15px 40px; 
        a{
            width: 100%;
        }
    }
}