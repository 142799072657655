.reputation {

    .reputation-item:not(last-child) {
        margin: 0 15px 15px 0;
        padding: 30px;

        color: $blue;

        height: auto;
        width: 240px;

        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        .reputation-item {
            width: auto !important;
            height: auto !important;

            margin: 0 !important;
        }

        .reputation-item:nth-child(even) {
            margin-right: 0;
        }

    }

}