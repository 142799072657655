/*
|--------------------
|    FONTS IMPORTS
|--------------------
|
| 100 - Extra Light or Ultra Light
| 200 - Light or Thin
| 300 - Book or Demi
| 400 - Normal or Regular
| 500 - Medium
| 600 - Semibold, Demibold
| 700 - Bold
| 800 - Black, Extra Bold or Heavy
| 900 - Extra Black, Fat, Poster or Ultra Black
|
*/

// @font-face {
//     font-family: 'helvetica-neue';
//     src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot');
//     src: url('../fonts/helvetica-neue/helveticaneuelt-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff2') format('woff2'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.woff') format('woff'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.ttf') format('truetype'),
//         url('../fonts/helvetica-neue/helveticaneuelt-webfont.svg#helveticaneuelight') format('svg');
//     font-weight: 400;
//     font-style: normal;
// }

@font-face {
    font-family: 'arial';
    src: url('../fonts/arial/arial-webfont.eot');
    src: url('../fonts/arial/arial-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arial/arial-webfont.woff2') format('woff2'),
        url('../fonts/arial/arial-webfont.woff') format('woff'),
        url('../fonts/arial/arial-webfont.ttf') format('truetype'),
        url('../fonts/arial/arial-webfont.svg#arialregular') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'arial';
    src: url('../fonts/arial/arial_bold-webfont.eot');
    src: url('../fonts/arial/arial_bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/arial/arial_bold-webfont.woff2') format('woff2'),
        url('../fonts/arial/arial_bold-webfont.woff') format('woff'),
        url('../fonts/arial/arial_bold-webfont.ttf') format('truetype'),
        url('../fonts/arial/arial_bold-webfont.svg#arialbold') format('svg');
    font-weight: 700;
    font-style: normal;
}