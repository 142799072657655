/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 70px 0 0px 0;
  background-color: $blue;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  line-height: initial;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  .label-container{
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
  }

  a {
    text-transform: none;
    color: #ffffff;
    font-family: Arial;
    font-size: 15px;
    line-height: initial;
  }
  h3 {
    padding-bottom: 20px;
    font-weight: bold;
    color: $white;
  }
  .ft1 {
    padding: 0 50px;
    border-right: 1px solid #3d6589;
    table {
      margin: 20px 0;
      @include media-breakpoint-down(md) {
        margin: 20px auto;
      }
    }
    .adr {
      margin: 30px 0 20px 0;
    }
    .ft-contact {
      padding: 10px 0;
    }
    .aide {
      margin: 20px 0;
      .pm-up {
        margin-left: 20px;
        display: inline-block;
      }
    }
  }
  .ft2 {
    padding: 0 50px;
    ul {
      margin: 0 0 40px 0;
      li {
        padding: 10px 0;
        list-style: disc;
        margin-left: 15px;
        @include media-breakpoint-down(md) {
          list-style: none;
          margin-left: 0px;
        }
      }
    }
    .label {
      max-width: 80px;
      max-height: 80px;
      margin-bottom: 25px;
    }
  }
  .ft3 {
    padding: 0 50px;
    .newstxt {
      margin-bottom: 30px;
    }
  }
  @include media-breakpoint-down(md) {
    .ft {
      border: none;
    }
  }
  td{
      color: #FFF;
      font-family: $font-family-default;
      font-weight: 100;
  }

  .footer-legals {
    display: flex;
    justify-content: center;
    background-color: $dark-blue;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    &-item {
      margin: 0 50px;

      @include media-breakpoint-down(sm) {
        margin: 20px 0;
      }
    }
  }
}