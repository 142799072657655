/*
|--------------------
|      BUTTONS
|--------------------
*/
.btn{
    @include media-breakpoint-down(md) {
        padding: 12px 30px;
    }
}


.btn-blue{
    &:hover{
        background: rgba($white, .5);
        color: $blue;
    }
}

.btn-white {
    &:hover {
        background: $blue;
        color: $white;
    }
}