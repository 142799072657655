/*
|--------------------
|      CONTENT
|--------------------
*/

.small-banner {
    position: relative;

    .content{
        position: relative;
        z-index: 1;
    }

    &:after{
        content: "";

        z-index: 0;

        position: absolute;

        left: -100px;
        top: 0;

        width: 50%;
        height: 100%;

        background: rgba($blue, .7);
        transform: skewX(-12deg);

        @include media-breakpoint-down(md) {
            width: 100%;
            left: 0;
            transform: none;
        }
    }
}


/*
|
| Breadcrumbs
|---------------
*/
.breadcrumbs{
    span{
        display: initial;
        span{
            a{
                margin-left: 0 !important;
            }
        }
    }
    a{
        margin-left: 15px;
        margin-right: 15px;
        color: $light-grey;
    }
    .breadcrumb_last {
        color: $blue;
        font-weight: 700;
        margin-left: 15px;
    }
}

/*
|
| Banner
|---------
*/
.banner {
    position: relative;

    width: 100%;

    padding: 50px 0;

    background-color: $blue;
    color: $white;

}

/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  background-color: $blue;
  color: $white;

  a:hover {
    color: $white
  }

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}

/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}

/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

	ul{
		li{
			display: flex;
			align-items: center;

			&:before{
				content: "•";
				margin-right: 5px;
			}
		}
	}

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
  }

  h1 {
    @extend .title-xl;
  }
  h2 {
    @extend .title-lg;
  }
  h3 {
    @extend .title-md;
  }
  h4 {
    @extend .title-sm;
  }
  h5 {
    @extend .title-xs;
  }
  h6 {
    @extend .title-xs;
  }

  iframe{
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
  }

  img {
    display: block;
    // width: 100%;
    // height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    margin-bottom: 20px;
  }

  &.cms-no-break {
    p {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url('../img/icon/quote-left.svg');

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url('../img/icon/quote-right.svg');

      width: 50px;
      height: 50px;

      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}

/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;

  @include media-breakpoint-down(lg) {
    padding-top: $headerHeightSm;
  }
}

/*
|
| Custom Loader
|---------------
*/
.loading,
.loading-banner {
    display: none;
    font-weight: 700;
}

.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}


/*
|
| Title
|-----------
*/
.little-title-md{
    @extend .title-md;
    font-weight: 300;
    color: $grey;
}
.little-title-sm {
    @extend .title-sm;
    font-weight: 300;
    color: $grey;
}

/*
|
| Bg images
|-----------
*/
.bg-img {
    @extend .bg-cover;

    &:after {
        content: '';
        display: block;
    }
}

.bg-img-full{
    @extend .bg-img;
    height: 100%;
    
    @include media-breakpoint-down(md) {
        &:after {
            padding-bottom: 70%;
        }
    }
}

.bg-img-full-xl {
    @extend .bg-img;
    height: 100%;

    @include media-breakpoint-down(lg) {
        &:after {
            padding-bottom: 70%;
        }
    }
}

.bg-img-square {
    @extend .bg-img;

    &:after {
        padding-bottom: 100%;
    }
}

.bg-img-landscape {
    @extend .bg-img;

    &:after {
        padding-bottom: 70%;
    }
}

.bg-img-almost-square {
    @extend .bg-img;

    &:after {
        padding-bottom: 85%;
    }
}

.bg-img-landscape-sm {
    @extend .bg-img;

    &:after {
        padding-bottom: 50%;
    }
}

.bg-img-portrait {
    @extend .bg-img;

    &:after {
        padding-bottom: 130%;
    }
}


.zone-filters {
    .bloc-filter{
        padding-bottom: 10px;
        margin-bottom: 10px;

        &:first-child{
            padding-top: 10px;
        }
        
        .item-bf-label{
            color: $dark-blue;
            font-weight: bold;
            margin-bottom: 5px;
        }

        &:not(:last-child){
            border-bottom: 1px solid $border-color;
        }
    }
}