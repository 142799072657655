#page-product{

    .product-main-image {
        @include media-breakpoint-down(md) {
            width: 60%;
            display: block;
            margin: auto;
        }
    }

    .specifications {

        .head-specs{
            display: flex;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }

            .head-item{
                width: 20%;
                padding: 20px;
                
                background: $white;
                transition: .5s all;

                cursor: pointer;

                &:hover{
                    color: $white;
                    background: $blue;

                    @include media-breakpoint-down(md) {
                        color: $grey;
                    }
                }

                &.active{
                    background: $specs-grey;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                    text-align: center;
                }
            }
        }

        [data-body-id]:not([data-body-id="0"]){
            display: none;
        }

        .body-item{
            display: none;
            
            &.active{
                display: block;
            }

        }

        .body-specs{
            margin-top: 1px;
            padding: 15px 30px;
            
            @include media-breakpoint-down(md) {
                padding: 15px 10px;
            }

            background: $specs-grey;

            .tab-line{
                padding: 5px;
                transition: .2s all;

                &:hover{
                    background: $white !important;
                }
            }

            .tab-line:nth-child(even){
                background: $shop-grey;
            }
        
            .list{
                padding: 5px;

                .list-item{
                    padding: 5px;
                    
                    display: flex;
                    align-items: center;

                    .item-name{
                        &:before {
                            content: "•";
                            margin-right: 5px;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }

        .download-item{
            width: 100%;

            .pdf-link {

                &:before {
                    content: "•";
                    margin-right: 5px;
                    padding-left: 15px;
                }

                &:hover {
                    color: $blue;
                }
            }
        }


    }

        /*
        |
        | ADD TO CART BUTTON
        |
        */
        .add-cart{
            .stock, .quantity{
                display: none;
            }
            .single_add_to_cart_button{
                background-color: transparent;
                color: $blue;

                position: relative;

                padding: 0;

                &:after{
                    content: "";
                    position: absolute;

                    bottom: -2px;
                    left: 0;
                    
                    width: 100%;
                    height: 1px;

                    background: $blue;
                    transition: .3s all;
                }

                &:hover{
                    &:after{
                        width: 0;
                    }
                }
            }
        }

}