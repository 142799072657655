
.deploy-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    .arrow-filter{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        height: 16px;
        width: 16px;
    }
}

.deploy-section{
    overflow: hidden;
}


/*
|
| Input WooCommerce
|
*/
.form-row{
    width: 100% !important;

    //Simple input and textarea
    .woocommerce-input-wrapper{
        width: 100%;

        input, textarea{
            padding: 10px;
            border: 1px solid $blue;
        }

    }

    //Selection
    .selection{
        width: 100%;
        .select2-selection--single{
            padding: 10px;

            border: 1px solid $blue;
            border-radius: 0;

            height: auto !important;

            .select2-selection__arrow{
                top: 50% !important;
                transform: translateY(-50%);
            }
        }
    }

}


/*
|
| Table WooCommerce
|
*/
.woocommerce{
    table{
        .shop_table{
            border-radius: 0;
        }
    }
} 