.product-card{

    .product-card-inner{
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;


        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }

        .product-card-title{
            @extend .little-title-sm;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

        }

        .specs{
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }

        .product-image {
            height: 195px;

            @include media-breakpoint-down(md) {
                height: 125px
            }

            img {
                height: 100%;
            }
        }
    }


}