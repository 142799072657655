/*
|---------------------------------------------------------------
| COLORS
|---------------------------------------------------------------
| Define colors
|
|
*/

/*
|
| Colors declaration
|----------------------
|
*/
$black:               #000000;
$very-dark-grey:      #141414;
$dark-grey:           #424242;
$grey:                #666666;
$specs-grey:          #F4F4F4;
$light-grey:          #777777;
$shop-grey:           #DFDFDC;
$very-light-grey:     #fafafa;
$white:               #FFFFFF;
$blue:                #013665;
$dark-blue:           #013159;
$white-opacity:       rgba(255, 255, 255, 0.8);
$black-opacity:       rgba(0, 0, 0, 0.6);
$red:                #DF280A;


/*
|
| Assign colors
|---------------
|
*/
$border-color:                  $grey;
$text-color:                    $grey;
$default-background-overlay:    $black-opacity;


/*
|-------------
| Colors map 
|-------------
| - How to use: add lines to create more "color classes" 
| - Generated classes: .bg-{color} & .color-{color}
|
*/
$colors : (
  //Required colors
  'black'          : $black,
  'very-dark-grey' : $very-dark-grey,
  'dark-grey'      : $dark-grey,
  'specs-grey'     : $specs-grey,
  'grey'           : $grey,
  'light-grey'     : $light-grey,
  'very-light-grey': $very-light-grey,
  'white'          : $white,
  'blue'           : $blue,
  'red'            : $red, 
  'shop-grey'      : $shop-grey,
);



