/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| OVERMENU
|-------
*/
#overhead {
    background: $blue;
    position: relative;
    z-index: 10;
    .top-zone {
        .login-menu {
            color: #ffffff;
            font-family: Arial;
            font-size: 13px;
        }
    }
}

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
    background: #fff;
    width: 100%;
    position: absolute;
    z-index: 10;
    padding: 50px 0 15px 0;

    @include media-breakpoint-down(md) {
        padding: 15px 0;

        .ai-end{
            align-items: center;
        }
    }

    .item-logo {
        height: 45px;

        @include media-breakpoint-down(md) {
            height: 35px;
        }

        img {
            height: 100%;
        }
        svg {
            path, polygon {
            fill: $white;
            }
        }
    }

    .item-nav {
        .item-menu {

            li:not(:last-child){
                margin-right: 20px;
            }

            li {
            display: inline-block;

                .item-link {
                    font-family: Arial, Verdana, sans-serif;
                    font-size: 16px;
                    font-weight: 700;
                    padding: 0;
                    color: #777;
                    text-transform: uppercase;
                    transition: all ease 0.3s;
                    &:hover {
                    transition: all ease 0.3s;
                    color: rgba(119, 119, 119, 0.5);
                    }
                }
            }
        }
    }

    .menu-desktop{
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    @extend .link-menu;
    cursor: pointer;

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $very-dark-grey;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

}

/*
|
| Menu
|-------
*/
#mobile-menu {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $white;
    overflow: auto;

    .item-container {
        @extend .container;

        ul {
        & > li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            a {
                text-transform: uppercase;
                color: $very-dark-grey;
                font-size: 30px;
                font-weight: 500;
                -webkit-font-smoothing: antialiased;

                @include media-breakpoint-down(xs) {
                    font-size: 26px;
                }
            }
        }
        }
    }

    .item-socials-container {

        margin-top: 10px;

        .item-socials {
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            a {
                font-size: 25px;

                &:not(:last-child) {
                margin-right: 25px;
                }
            }

            @include media-breakpoint-down(xs) {
                margin-bottom: 15px;

                a {
                font-size: 20px;
                }
            }
        }
    }

}