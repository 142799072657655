/*
|--------------------
|      NEWS
|--------------------
*/

.md-down-f-column{
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}

//Back button
.back{
    .back-btn{
        position: relative;

        &:after{
            content: "";

            position: absolute;

            width: 100%;
            height: 1px;

            bottom: 2px;
            left: 0;

            background-color: $grey;

            transition: .6s width;
        }

        &:hover{
            &:after{
                width: 0;
            }
        }
    }
}

.custom-card {

    padding: 25px 0;
    border-bottom: 2px solid $specs-grey;

    transition: .3s all;

    &:hover{
        background-color: $specs-grey;
        .title-archive-article {
            color: $grey;
        }
    }

    .title-archive-article{
        color: $blue;

        transition: .3s color;
    }

    .custom-card-link { 
        display: block;
    }

    /* 
    * Card img
    */
    .custom-card-image {
        max-width: 250px;
        height: 250px;
        width: 250px;
        flex: 1 0 auto;
        margin-right: 15px;

        @include media-breakpoint-down(md) {
            width: 100%;
            height: 150px;
        }

    }
}